import React, { FC, useState, useEffect } from 'react';
import { Button, ButtonBar, Modal, Heading, Form } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Currency, Document } from 'src/types';
import currencySymbols from '@tg/core/utils/currencySymbols';
import { useResource } from '@tg/core/hooks';
import { ResourceOutput } from '@tg/core/hooks/useResource';
import { formatNumber } from '@tg/core/utils/numberHelpers';
import FilePicker from './FilePicker';

interface Props {
  defaultValues: FormData;
  payrollId: string;
  submission_id: string;
  userFullName: string;
  currency: Currency;
  emp_id: number;
  getList: ResourceOutput<FormData>['getResource'];
}

interface FormData {
  bonuses: string;
  expenses: string;
  commisions: string;
  receipts: File[] | Document[];
  notes: string;
  gross_salary: string;
}

const ExpensesModal: FC<Props> = ({
  defaultValues,
  currency,
  payrollId,
  submission_id,
  emp_id,
  userFullName,
  getList,
}) => {
  const { t } = useTranslation(['payroll', 'forms']);
  const validExtensions = [
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'txt',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'csv',
    'ppt',
    'pptx',
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });
  const [errorFiless, setErrorFiless] = useState(0);

  const { patchResource, isFetching, errors } = useResource({
    url: `employers/payroll_adjustments/${submission_id}/sheets`,
  });

  const validationSchema = null; // TODO
  const { handleSubmit, getFieldProps } = Form.useForm<FormData>({
    validationSchema,
  });

  const onSubmit = (values: { payroll: FormData }) => {
    const { commisions, bonuses, expenses, notes } = values.payroll;

    const formData = new FormData();
    receipts.forEach(file => {
      // Newly uploaded files are a File, old ones are a Document type
      if (file instanceof File) {
        formData.append('receipts[]', file);
      }
    });
    formData.append('commisions', commisions || '0.0');
    formData.append('bonuses', bonuses || '0.0');
    formData.append('expenses', expenses || '0.0');
    formData.append('notes', notes);
    patchResource({
      id: payrollId,
      formData,
      headers: {},
      onSuccess: () => {
        getList({
          onSuccess: () => setIsOpen(false),
        });
      },
      onError: error => {
        if (error?.response?.error?.includes('invalid file type')) {
          setIsValidFormat({
            message: error?.response?.error,
            isValid: false,
          });
        }
        if (error?.response?.error?.includes('is invalid')) {
          setIsValidFormat({
            message:
              'Commas are not allowed in the Expense, Bonus, or Commission fields. Please remove any commas and try again.',
            isValid: false,
          });
        }
      },
    });
  };

  const handleCheck = () => {
    return true;
  };

  const invalidFiles = receipts?.filter(file => {
    const extension = file?.path?.split('.').pop()?.toLowerCase();
    return !validExtensions?.includes(extension);
  })?.length;

  const handleErrorModalClose = () => {
    setErrorFiless(invalidFiles);
    setIsValidFormat({ message: null, isValid: true });
    setTimeout(() => {
      setErrorFiless(0);
    }, 500);
  };

  return (
    <>
      <Button
        type='button'
        onClick={() => setIsOpen(true)}
        color='tertiary'
        size='small'
      >
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        title={
          userFullName
            ? t('payroll:expenses.modal.title', {
                fullName: userFullName,
              })
            : ''
        }
        width='sm'
        onClose={() =>
          defaultValues.receipts.length
            ? getList({
                onSuccess: () => setIsOpen(false),
              })
            : setIsOpen(false)
        }
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          // isFetching={isFetching}
        >
          <Modal.Content>
            <div className='space-y-4'>
              <fieldset className='space-y-4'>
                {/* <Heading element='legend' level='h4' zeropad>
                  Bonuses
                </Heading> */}
                <div className='grid gap-4 grid-cols-2'>
                  <Form.TextField
                    {...getFieldProps({ id: 'payroll', name: 'bonuses' })}
                    postInput={currency.code}
                    type='number'
                    min='0'
                    defaultValue={defaultValues.bonuses}
                  />

                  <Form.TextField
                    {...getFieldProps({ id: 'payroll', name: 'commisions' })}
                    postInput={currency.code}
                    type='number'
                    min='0'
                    defaultValue={defaultValues.commisions}
                  />
                </div>
              </fieldset>
              <fieldset className='space-y-4'>
                <Heading element='legend' level='h4' zeropad>
                  Expenses
                </Heading>
                <div className='w-60'>
                  <Form.TextField
                    {...getFieldProps({ id: 'payroll', name: 'expenses' })}
                    postInput={currency.code}
                    type='number'
                    min='0'
                    defaultValue={defaultValues.expenses}
                  />
                </div>
                <Form.TextAreaField
                  {...getFieldProps({ id: 'payroll', name: 'notes' })}
                  height='80px'
                  defaultValue={defaultValues.notes}
                />
                <FilePicker
                  defaultValue={defaultValues.receipts}
                  onChange={newFiles => setReceipts(newFiles)}
                  getEmployeeId={emp_id}
                  handleCheck={handleCheck}
                  fileError={errorFiless}
                />
              </fieldset>
            </div>
          </Modal.Content>
          <ButtonBar>
            <Button type='submit' loading={isFetching}>
              Submit
            </Button>
          </ButtonBar>
        </Form>
      </Modal>

      <Modal
        width='xs'
        isOpen={!isValidFormat?.isValid}
        title='Error'
        onClose={() => {
          setIsValidFormat({ message: null, isValid: true });
        }}
      >
        <Modal.Content>{isValidFormat?.message}</Modal.Content>
        <ButtonBar>
          <Button
            color='secondary'
            appearance='outline'
            onClick={handleErrorModalClose}
          >
            ok
          </Button>
        </ButtonBar>
      </Modal>
    </>
  );
};

export default ExpensesModal;
